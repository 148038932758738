<template>
  <v-container class="h-default w-full pa-0 mt-3 d-flex flex-column" fluid>
    <section-header
      :title="$t('settingsPage.notifications')"
      :has-action="false"
    />

    <v-card class="py-6 px-6 mt-3 flex-grow-1" rounded="lg" elevation="0" width="100%">
      <v-row>
        <v-col cols="12" sm="8" offset-sm="2">
          <p class="text-left text-h6">
            {{ $t('notifications.emailNotifications') }}
          </p>

          <div class="d-flex align-center">
            <p class="text-body-2 mb-0">
              {{ $t('notifications.newsEmailList') }}
            </p>
            <v-spacer />
            <v-switch
              v-model="newsEmail"
              inset
              hide-details
              class="custom-switch mt-0"
              @change="onUpdateNotifications('newEmail')"
            />
          </div>
        </v-col>

        <v-col cols="12" sm="8" offset-sm="2" class="mt-4 text-left">
          <p class="text-h6">
            {{ $t('notifications.systemNotifications') }}
          </p>

          <p class="text-body-1">
            {{ $t('testCases') }}
          </p>

          <div>
            <p class="text-body-2 mb-1">
              {{ $t('notifications.notifyMe') }}
            </p>
            <v-select
              v-model="testCaseNotifyType"
              :items="notifyTypes"
              dense
              filled
              item-text="text"
              item-value="value"
              @change="onUpdateNotifications('newNotify')"
            />
          </div>

          <div class="d-flex">
            <p class="text-body-2 mb-0">
              {{ $t('notifications.testCaseAssignedToMe') }}
            </p>
            <v-spacer />
            <v-switch
              v-model="newTestCaseAssignedToMe"
              inset
              hide-details
              class="custom-switch mt-0"
              @change="onUpdateNotifications('newTestcase')"
            />
          </div>

          <div class="d-flex mt-3">
            <p class="text-body-2 mb-0">
              {{ $t('notifications.newCommentAssignedToMe') }}
            </p>
            <v-spacer />
            <v-switch
              v-model="newCommentAssignedToMe"
              inset
              hide-details
              class="custom-switch mt-0"
              @change="onUpdateNotifications('newCommentTestcase')"
            />
          </div>

          <p class="text-body-1 mt-4">
            {{ $t('defects') }}
          </p>

          <div>
            <p class="text-body-2 mb-1">
              {{ $t('notifications.notifyMe') }}
            </p>
            <v-select
              v-model="defectNotifyType"
              :items="notifyTypes"
              dense
              filled
              item-text="text"
              item-value="value"
              @change="onUpdateNotifications('newDefect')"
            />
          </div>

          <div class="d-flex">
            <p class="text-body-2 mb-0">
              {{ $t('notifications.newCommentDefects') }}
            </p>
            <v-spacer />
            <v-switch
              v-model="newCommentDefects"
              inset
              hide-details
              class="custom-switch mt-0"
              @change="onUpdateNotifications('newCommentDefect')"
            />
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import makeUserService from '@/services/api/user';

import SectionHeader from '@/components/Form/SectionHeader.vue';

import { showSuccessToast,showAlertToast, showErrorToast } from '@/utils/toast';

export default {
  name: 'Notifications',

  components: {
    SectionHeader,
  },

  data () {
    return {
      notifyTypes: [
        { value: 'platform', text: this.$t('notifications.inPlatformOnly') },
        { value: 'emailPerDay', text: this.$t('notifications.inPlatformEmailPerDay') },
        { value: 'emailImmediate', text: this.$t('notifications.inPlatformEmailImmediate') },
      ],
      newsEmail: true,
      newTestCaseAssignedToMe: true,
      newCommentAssignedToMe: true,
      newTestRunAssignedToMe: true,
      newCommentDefects: true,
      testCaseNotifyType: 'platform',
      testRunNotifyType: 'emailPerDay',
      defectNotifyType: 'emailImmediate',
      originalPreferences: {},
    }
  },

  computed: {
    ...mapGetters({ currentUser: 'user/user' }),
  },

  mounted() {
    this.newsEmail = this.currentUser.preferences?.newsEmail === undefined ? true : this.currentUser.preferences.newsEmail
    this.newTestCaseAssignedToMe = this.currentUser.preferences?.newTestCaseAssignedToMe === undefined ? true : this.currentUser.preferences.newTestCaseAssignedToMe
    this.newCommentAssignedToMe = this.currentUser.preferences?.newCommentAssignedToMe === undefined ? true : this.currentUser.preferences.newCommentAssignedToMe
    this.newTestRunAssignedToMe = this.currentUser.preferences?.newTestRunAssignedToMe === undefined ? true : this.currentUser.preferences.newTestRunAssignedToMe
    this.newCommentDefects = this.currentUser.preferences?.newCommentDefects === undefined ? true : this.currentUser.preferences.newCommentDefects
    this.testCaseNotifyType = this.currentUser.preferences?.testCaseNotifyType === undefined ? 'platform' : this.currentUser.preferences.testCaseNotifyType
    this.testRunNotifyType = this.currentUser.preferences?.testRunNotifyType === undefined ? 'platform' : this.currentUser.preferences.testRunNotifyType
    this.defectNotifyType = this.currentUser.preferences?.defectNotifyType === undefined ? 'platform' : this.currentUser.preferences.defectNotifyType
    this.originalPreferences = this.currentUser.preferences
  },

  methods: {
    ...mapActions({
      setUser: 'user/setUser',
      setLoading: 'setLoading',
    }),

    async onUpdateNotifications(updateType) {

      const userService = makeUserService(this.$api);

      try {

        const response = await userService.updatePreferences({
          preferences: {
            ...this.currentUser.preferences,
            newsEmail: this.newsEmail,
            newTestCaseAssignedToMe: this.newTestCaseAssignedToMe,
            newCommentAssignedToMe: this.newCommentAssignedToMe,
            newTestRunAssignedToMe: this.newTestRunAssignedToMe,
            newCommentDefects: this.newCommentDefects,
            testCaseNotifyType: this.testCaseNotifyType,
            testRunNotifyType: this.testRunNotifyType,
            defectNotifyType: this.defectNotifyType,
          }
        })
        this.setUser({
          ...this.currentUser,
          preferences: response.data.preferences,
        })

        let notificationMsg
        if (updateType == 'newEmail')
          notificationMsg = "Email"
        else if (updateType == 'newNotify' || updateType == 'newTestcase' || updateType == 'newCommentTestcase')
          notificationMsg = "Test cases"
        else if (updateType == 'newDfect' || updateType == 'newCommentDefect')
          notificationMsg = "Defects"

        const result = await showAlertToast(this.$swal, this.$t('notifications.settingNotifications',{NotificationType: notificationMsg}))
        if (result == 'Undo')
        {
          if (updateType == 'newEmail')
            this.newsEmail = this.originalPreferences.newsEmail;
          else if (updateType == 'newNotify')
            this.testCaseNotifyType = this.originalPreferences.testCaseNotifyType;
          else if (updateType == 'newTestcase')
            this.newTestCaseAssignedToMe = this.originalPreferences.newTestCaseAssignedToMe;
          else if (updateType == 'newCommentTestcase')
            this.newCommentAssignedToMe = this.originalPreferences.newCommentAssignedToMe;
          else if (updateType == 'newDefect')
            this.defectNotifyType = this.originalPreferences.defectNotifyType;
          else if (updateType == 'newCommentDefect')
            this.newCommentDefects = this.originalPreferences.newCommentDefects;
        }
        else if (result == 'Close') {
          this.originalPreferences = {
            ...this.currentUser.preferences,
            newsEmail: this.newsEmail,
            newTestCaseAssignedToMe: this.newTestCaseAssignedToMe,
            newCommentAssignedToMe: this.newCommentAssignedToMe,
            newTestRunAssignedToMe: this.newTestRunAssignedToMe,
            newCommentDefects: this.newCommentDefects,
            testCaseNotifyType: this.testCaseNotifyType,
            testRunNotifyType: this.testRunNotifyType,
            defectNotifyType: this.defectNotifyType,
          }
        }

      } catch (err) {
        console.log(err)
        showErrorToast(this.$swal, err.response?.data?.message || 'Internal server error')
      } finally {
        this.originalPreferences = {
            ...this.currentUser.preferences,
            newsEmail: this.newsEmail,
            newTestCaseAssignedToMe: this.newTestCaseAssignedToMe,
            newCommentAssignedToMe: this.newCommentAssignedToMe,
            newTestRunAssignedToMe: this.newTestRunAssignedToMe,
            newCommentDefects: this.newCommentDefects,
            testCaseNotifyType: this.testCaseNotifyType,
            testRunNotifyType: this.testRunNotifyType,
            defectNotifyType: this.defectNotifyType,
          }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.h-default {
  height: calc(100vh - 100px);
}

.custom-switch {
  ::v-deep .v-input--switch__thumb {
    width: 24px;
    height: 24px;
    top: 0;
    right: 2px;
  }

  ::v-deep .primary--text{
    background-color: #ffffff !important; /* Custom track color when switch is on */
  }

  ::v-deep .primary--text.v-input--switch__track {
    background-color: #0000ff !important; /* Custom thumb color */
    opacity: 1;
  }
}

.swal-back {
  width: 440px;
}

</style>